<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="cancel-popup" slot="component">
      <form @submit.prevent="submitReasonForm">
        <!-- Heading Sesction -->
        <div class="cancel-popup_heading">
          {{ $t("It’s sad to see you Go!") }}
        </div>

        <br />

        <div class="cancel-popup_heading_sub-heading">
          {{ $t("To improve the service, Please tell us why you choose to unsubscribe.") }}
        </div>

        <!-- Reasons Dropdown -->

        <br />

        <!-- Error Container -->
        <div class="error-container" v-if="formError">
          <p class="error">{{ $t(formError) }}</p>
        </div>

        <br />

        <div class="cancel-popup_sub-heading" style="marginBottom:10px">
          {{ $t("Select Reason") }}
        </div>

        <!-- <select class="drop-layout-cancel" v-model="reasonObj" @change="selectReason">
          <option value="" selected disabled>{{ $t("Please choose one of these reasons") }}</option>
          <option :value="reasonName" v-for="(reasonName, index) in reasonsList" :key="index">{{
            localDisplayLang === "eng" ? reasonName.en : reasonName.ar
          }}</option>
        </select> -->
     
          <CustomSelect
          :options="reasonsList"
          :displayKey=" localDisplayLang === 'hi' ? 'ar' : 'en'"
          width="max"
          v-if="reasonsList.length > 0"
          :hint="$t('Please choose one of these reasons')"
          @onSelectChange="onItemSelected"

        />

     

        <br /> <br />

        <!-- Other reason Text box -->
        <div>
          <label for="otherReason">{{ $t("Please type reason here...") }}</label>
          <textarea
            :placeholder="`${this.$t('Please enter reason')}`"
            v-model="otherReason"
            name="otherReason"
            id="otherReason"
            cols="30"
            rows="5"
          ></textarea>
        </div>

        <!-- The Submit button -->
        <button type="submit" class="button-primary submit">{{ $t("Submit") }}</button>
      </form>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../eventBus";
export default {
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    CustomSelect: () => import("@/components/utils/CustomSelect.vue"),
  },

  props: {
    closePopup: {
      type: Function,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      reasonObj: null,
      reason: "",
      localDisplayLang: null,

      reasonsList: [],
      isReasonTextBox: false,
      otherReason: "",
      formError: "",
    };
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  watch: {
    // reason(val) {
    //   if (
    //     val === "Other" ||
    //     val === "سبب أخر" ||
    //     val === "I wanted to change my payment method to. Please add it to the message." ||
    //     val === "أرغب في تعديل طريقة دفعي للاشتراك . الرجاء ذكرها في النص"
    //   ) {
    //     this.isReasonTextBox = true;
    //   } else {
    //     this.otherReason = "";
    //     this.isReasonTextBox = false;
    //   }
    // },

    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = "";
        }, 5000);
      }
    },
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.reasonsList = this.appConfig.cancellationReasons;

    console.log("THIS IS THE CANCEL POP UP CONFIG -- ", this.reasonsList);
  },

  mounted() {
    this.reasonObj = "";
  },

  methods: {
    selectReason() {
      this.reason = this.localDisplayLang === "eng" ? this.reasonObj.en : this.reasonObj.ar;
    },

    onItemSelected(payload){
      let reasonObjPayload = payload.selectedItem;
      this.reason = this.localDisplayLang === "eng" ? reasonObjPayload.en : reasonObjPayload.ar;
    },

    submitReasonForm() {
      if (!this.reason) {
        this.formError = "Please enter reason";
        return;
      }

      if (this.reasonObj.isReasonMandatory && !this.otherReason) {
        this.formError = this.$t("Please enter the comment");
        return;
      }

      if ((this.reason && this.reason.length > 256) || (this.otherReason && this.otherReason.length > 256)) {
        this.formError = "The comment should be less than 256 characters";
        return;
      }

      const reasonPayload = {
        reason: `${this.reason}${this.otherReason ? " : " + this.otherReason : ""}`,
      };

      console.log("THE FINAL PAYLOAD - ", reasonPayload);
      // localStorage.setItem("cancelReasonPayload", reasonPayload);
      eventBus.$emit("submit-cancel-reponse-event", reasonPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./SubscriptionCancelReason.scss"
</style>
